<template>
	<span>
		<div class="text-center" v-if="column.disabled">
			<v-icon large v-if="item[column.value]">mdi-check</v-icon>
			<span v-else>-</span>
		</div>
		<v-checkbox v-else :value="item[column.value]" @change="update"></v-checkbox>
	</span>
</template>

<script>
export default {
	name: "CheckboxCell",
	props: ["item", "column"],
	methods: {
		update(v) {
			this.$store.dispatch("appointmentParticipants/patch", {id: this.item.participant_id, [this.column.value]: v ? true : false })
		},
	},
};
</script>
